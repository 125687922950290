import jsZip from 'jszip'
import { saveAs } from 'file-saver'
import { node } from '@/plugins/axios'

export const getBatchPdfs = async (pdfs, type, downloadType = 'zip') => {
  const generatedPdfs = await Promise.all(pdfs.map(getBatchPdfData))
  if (downloadType === 'zip') downloadZipFile(generatedPdfs, type)
  else downloadPdfFiles(generatedPdfs, type)
}

export const getBatchPdfData = async (pdf) => {
  const html = convertStringToBase64(pdf.pdfString)

  const { data } = await sendFileToPdfGenerator({
    html
  })

  return {
    pdf: data.data,
    pdfName: pdf.pdfName,
  }
}

const sendFileToPdfGenerator = (data) => {
  return node.post('/pdf/create', data, {
    timeout: 30 * 1000,
  })
}

const downloadZipFile = (pdfs, type) => {
  const zip = new jsZip()
  pdfs.map(pdf => {
    zip.file(`${pdf.pdfName}.pdf`, createBlob(pdf.pdf))
  })
  zip.generateAsync({ type: 'blob' }).then(content => {
    saveAs(content, `${type}-pdfs.zip`)
  })
}

const downloadPdfFiles = pdfs => {
  pdfs.forEach((pdf, idx) => {
    const internetExplorer = navigator.msSaveOrOpenBlob
    if (internetExplorer) internetExplorerDownload(pdf.pdf, pdf.pdfName)
    else setTimeout(() => standardDownload(pdf.pdf, pdf.pdfName), 150 * idx)
  })
}

const internetExplorerDownload = (base64Pdf, pdfName) => {
  const blob = createBlob(base64Pdf)
  navigator.msSaveOrOpenBlob(blob, pdfName)
}

const standardDownload = (base64Pdf, pdfName) => {
  const linkSource = `data:application/pdf;base64,${base64Pdf}`
  const downloadLink = document.createElement('a')
  document.body.appendChild(downloadLink)

  downloadLink.href = linkSource
  downloadLink.download = pdfName
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

const createBlob = base64Pdf => {
  const binary = atob(base64Pdf.replace(/\s/g, ''))
  const buffer = new ArrayBuffer(binary.length)
  const view = new Uint8Array(buffer)
  for (let i = 0; i < binary.length; i++) {
    view[i] = binary.charCodeAt(i)
  }

  return new Blob([view], { type: 'application/pdf' })
}

const convertStringToBase64 = string => {
  return Buffer.from(string).toString('base64')
}
